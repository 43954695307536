define("socialchan/transforms/json", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Transform = _emberData.default.Transform;

  class JsonTransform extends Transform {
    deserialize(serialized) {
      return JSON.parse(serialized);
    }

    serialize(deserialized) {
      return JSON.stringify(deserialized);
    }

  }

  _exports.default = JsonTransform;
});