define("socialchan/models/user", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-data", "@ember-decorators/data", "@ember-decorators/service", "@ember-decorators/object", "socialchan/mixins/model-timestamps"], function (_exports, _decorate2, _emberData, _data, _service, _object, _modelTimestamps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Model = _emberData.default.Model;
  let User = (0, _decorate2.default)(null, function (_initialize, _Model$extend) {
    class User extends _Model$extend {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: User,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "nickname",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "email",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('boolean')],
        key: "active",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('boolean')],
        key: "following",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.belongsTo)('profile')],
        key: "profile",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.hasMany)('user', {
          inverse: null
        })],
        key: "followers",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.hasMany)('user', {
          inverse: null
        })],
        key: "follows",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.hasMany)('post', {
          inverse: 'user'
        })],
        key: "posts",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.hasMany)('group')],
        key: "groups",
        value: void 0
      }, {
        kind: "get",
        decorators: [(0, _object.computed)('id', 'auth.user.id')],
        key: "isLoggedIn",
        value: function isLoggedIn() {
          return Ember.get(this, 'id') === Ember.get(this, 'auth.user.id');
        }
      }, {
        kind: "field",
        key: "isActive",

        value() {
          return Ember.computed.equal('active', true);
        }

      }]
    };
  }, Model.extend(_modelTimestamps.default));
  _exports.default = User;
});