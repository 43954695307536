define("socialchan/authorizers/application", ["exports", "ember-simple-auth/authorizers/oauth2-bearer"], function (_exports, _oauth2Bearer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationAuthorizer extends _oauth2Bearer.default {}

  _exports.default = ApplicationAuthorizer;
});