define("socialchan/templates/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C5hvsM09",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"component\",[\"page/header\"],[[\"user\",\"class\"],[[23,[\"user\"]],\"bg-indigo-light p-3\"]]],false],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[1,[27,\"component\",[\"page/alt-footer\"],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/page.hbs"
    }
  });

  _exports.default = _default;
});