define("socialchan/templates/settings/privacy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SGIYOWqq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[11,\"class\",\"text-indigo-darkest mb-6\"],[9],[0,\"\\n  Privacidad\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"w-full\"],[9],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/settings/privacy.hbs"
    }
  });

  _exports.default = _default;
});