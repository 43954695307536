define("socialchan/components/page/alt-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PageAltFooter extends Ember.Component {}

  _exports.default = PageAltFooter;
});