define("socialchan/routes/group/index", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-simple-auth/mixins/authenticated-route-mixin", "@ember-decorators/service"], function (_exports, _decorate2, _authenticatedRouteMixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let GroupIndex = (0, _decorate2.default)(null, function (_initialize, _EmberRoute$extend) {
    class GroupIndex extends _EmberRoute$extend {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: GroupIndex,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }, {
        kind: "method",
        key: "model",
        value: function model() {
          let user_id = Ember.get(this, 'auth.user.id');
          return Ember.RSVP.hash({
            groups: this.get('store').query('group', {
              include: 'author,author.profile,groupType,members,members.profile'
            }),
            groupTypes: this.get('store').findAll('group-type'),
            myGroups: this.get('store').query('group', {
              author: user_id
            })
          });
        }
      }]
    };
  }, Ember.Route.extend(_authenticatedRouteMixin.default));
  _exports.default = GroupIndex;
});