define("socialchan/components/layout/alert-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LayoutAlertErrors extends Ember.Component {}

  _exports.default = LayoutAlertErrors;
});