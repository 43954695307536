define("socialchan/templates/components/element/custom-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gv9/+Fs0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"inline-block relative w-full\"],[9],[0,\"\\n  \"],[7,\"select\"],[11,\"class\",\"block appearance-none w-full bg-white border border-grey-light hover:border-grey px-4 py-2 pr-8 rounded shadow\"],[12,\"id\",[21,\"selectId\"]],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker\"],[9],[0,\"\\n    \"],[7,\"svg\"],[11,\"class\",\"fill-current h-4 w-4\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"viewBox\",\"0 0 20 20\"],[9],[7,\"path\"],[11,\"d\",\"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z\"],[9],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/element/custom-select.hbs"
    }
  });

  _exports.default = _default;
});