define("socialchan/templates/components/group/join-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QIg/SHpM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"custom\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isMember\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[12,\"class\",[28,[\"bg-transparent hover:bg-red border border-red rounded text-red text-sm hover:text-white font-bold py-1 px-2 \",[21,\"addClass\"]]]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fas fa-sign-out-alt\"],[9],[10],[0,\"\\n      Salir\\n    \"],[3,\"action\",[[22,0,[]],\"join\",[23,[\"groupId\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\"],[12,\"class\",[28,[\"bg-transparent hover:bg-green border border-green rounded text-green text-sm hover:text-white font-bold py-1 px-2 \",[21,\"addClass\"]]]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fas fa-sign-in-alt\"],[9],[10],[0,\"\\n      Unirse\\n    \"],[3,\"action\",[[22,0,[]],\"join\",[23,[\"groupId\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/group/join-button.hbs"
    }
  });

  _exports.default = _default;
});