define("socialchan/mirage/factories/profile", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: _emberCliMirage.faker.name.firstName,
    surname: _emberCliMirage.faker.name.lastName,
    bio: _emberCliMirage.faker.lorem.sentence,
    avatar: _emberCliMirage.faker.image.avatar,
    country: _emberCliMirage.faker.address.country,
    // bg_image: faker.image.imageUrl(256, 96),
    birthday: _emberCliMirage.faker.date.past,
    gender: _emberCliMirage.faker.random.boolean
  });

  _exports.default = _default;
});