define("socialchan/templates/components/layout/sidebar-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FmkXPunD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex justify-between p-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-auto\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"font-semibold text-xl leading-tight truncate\"],[9],[0,\"\\n      Notificaciones\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"opacity-25\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"far fa-bell fa-lg\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"ul\"],[11,\"class\",\"list-reset border-t border-indigo-dark text-sm mt-4\"],[9],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"cursor-pointer border-b border-indigo-dark hover:bg-indigo-dark truncate py-2 px-4\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fas fa-eye mr-1\"],[9],[10],[0,\" @d8vjork ha empezado a seguirte\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"cursor-pointer border-b border-indigo-dark hover:bg-indigo-dark truncate py-2 px-4\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fas fa-eye mr-1\"],[9],[10],[0,\" @gabrielpb1 ha empezado a seguirte\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"cursor-pointer border-b border-indigo-dark hover:bg-indigo-dark truncate py-2 px-4\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fas fa-comment mr-1\"],[9],[10],[0,\" @d8vjork ha comentado tu publicación\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/layout/sidebar-monitor.hbs"
    }
  });

  _exports.default = _default;
});