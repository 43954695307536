define("socialchan/routes/page/subscribe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PageSubscribe extends Ember.Route {}

  _exports.default = PageSubscribe;
});