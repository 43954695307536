define("socialchan/templates/components/layout/ads-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wN2QajHr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"bg-indigo-light shadow rounded py-8 mb-3\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"font-bold text-lg text-center text-white my-8\"],[9],[0,\"\\n    Publicidad\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/layout/ads-square.hbs"
    }
  });

  _exports.default = _default;
});