define("socialchan/templates/components/feed/user-single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s9pMlSgs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"user\",[23,[\"user\",\"id\"]]],[[\"class\"],[\"flex-no-shrink\"]],{\"statements\":[[0,\"  \"],[7,\"img\"],[11,\"class\",\"w-10 h-10 bg-grey rounded-full mr-3\"],[12,\"src\",[23,[\"user\",\"profile\",\"avatar\"]]],[11,\"alt\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"span\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"user\",[23,[\"user\",\"id\"]]],[[\"class\"],[\"text-black font-bold\"]],{\"statements\":[[0,\"    \"],[1,[27,\"concat\",[[23,[\"user\",\"profile\",\"name\"]],\" \",[23,[\"user\",\"profile\",\"surname\"]]],null],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-grey-dark text-sm font-normal\"],[9],[0,\"\\n      @\"],[1,[23,[\"user\",\"nickname\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"attach-tooltip\",null,[[\"class\",\"showDelay\",\"lazyRender\",\"interactive\"],[\"w-64 rounded-lg\",300,true,true]],{\"statements\":[[0,\"      \"],[1,[27,\"component\",[\"user/hovercard\"],[[\"user\"],[[23,[\"user\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/feed/user-single.hbs"
    }
  });

  _exports.default = _default;
});