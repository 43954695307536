define("socialchan/components/post/single", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "ember-concurrency-decorators", "@ember-decorators/object"], function (_exports, _decorate2, _service, _emberConcurrencyDecorators, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let PostSingle = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class PostSingle extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: PostSingle,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "store",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "ajax",
        value: void 0
      }, {
        kind: "field",
        key: "isActive",

        value() {
          return false;
        }

      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          drop: true
        })],
        key: "showComments",
        value: function* showComments() {
          if (!this.get('post.hasComments') && !this.get('isActive')) {
            const comments = this.get('store').query('comment', {
              filter: {
                post_id: this.get('post.id')
              }
            });
            yield this.set('post.comments_count', comments.length);
            yield this.set('post.comments', comments);
          }

          yield this.toggleProperty('isActive');
        }
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          drop: true
        })],
        key: "postPin",
        value: function* postPin() {
          // TODO: One place ajax URLs
          yield this.get('ajax').post('posts/' + this.get('post.id') + '/sticky');
        }
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          drop: true
        })],
        key: "deletePost",
        value: function* deletePost() {
          const post = this.get('post');
          yield post.deleteRecord();
          if (post.get('isDeleted')) yield post.save();
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "toggleActive",
        value: function toggleActive() {
          this.get('showComments').perform();
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "addPin",
        value: function addPin() {
          this.get('postPin').perform();
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "delete",
        value: function _delete() {
          this.get('deletePost').perform();
        }
      }]
    };
  }, Ember.Component);
  _exports.default = PostSingle;
});