define("socialchan/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bz5ZsUD2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"array-contains\",[[23,[\"landingPages\"]],[23,[\"currentRouteName\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex h-screen inner-application font-sans antialiased overflow-hidden\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"media\",[\"isTablet\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"component\",[\"layout/top-sidebar\"],[[\"class\",\"currentRouteName\"],[\"fixed pin-t\",[23,[\"currentRouteName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"component\",[\"layout/left-sidebar\"],[[\"currentRouteName\"],[[23,[\"currentRouteName\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[27,\"is-feature\",[\"chats\"],null]],null,{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex-1 flex flex-col bg-blue-lightest overflow-y-scroll pt-9 xl:pt-0 xl:pl-20 bg-cover bg-fixed bg-center bg-no-repeat\"],[12,\"style\",[27,\"html-safe\",[[23,[\"auth\",\"user\",\"profile\",\"bgImage\"]]],null]],[9],[0,\"\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/application.hbs"
    }
  });

  _exports.default = _default;
});