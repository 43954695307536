define("socialchan/components/layout/right-sidebar", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service"], function (_exports, _decorate2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LayoutRightSidebar = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class LayoutRightSidebar extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: LayoutRightSidebar,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }]
    };
  }, Ember.Component);
  _exports.default = LayoutRightSidebar;
});