define("socialchan/components/page/header", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object", "@ember-decorators/service"], function (_exports, _decorate2, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let PageHeader = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class PageHeader extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: PageHeader,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "media",
        value: void 0
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "logout",
        value: function logout() {
          this.get('session').invalidate();
        }
      }]
    };
  }, Ember.Component);
  _exports.default = PageHeader;
});