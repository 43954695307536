define("socialchan/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "socialchan/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Oauth2 extends _oauth2PasswordGrant.default {
    constructor() {
      super(...arguments);
      this.serverTokenEndpoint = _environment.default.APP.apiHost + '/oauth/token';
      this.serverTokenRevocationEndpoint = _environment.default.APP.apiHost + '/oauth/revoke';
    }

  }

  _exports.default = Oauth2;
});