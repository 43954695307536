define("socialchan/controllers/group/found", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service"], function (_exports, _decorate2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { computed } from '@ember-decorators/object'
  let GroupFound = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class GroupFound extends _EmberController {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: GroupFound,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "store",
        value: void 0
      }, {
        kind: "method",
        key: "init",
        value: function init() {
          this._super(...arguments); // this.get('store')


          this.set('group', {});
        }
      }]
    };
  }, Ember.Controller);
  _exports.default = GroupFound;
});