define("socialchan/controllers/application", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object"], function (_exports, _decorate2, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let menuItems = (0, _object.computed)('auth.user.id', function () {
    const user = this.get('auth.user.id');
    return [{
      route: 'user',
      param: user,
      icon: 'fas fa-user'
    }, {
      route: 'feed',
      param: '',
      icon: 'fas fa-comments'
    }, {
      route: 'group',
      param: '',
      icon: 'fas fa-users'
    }, {
      route: 'anime',
      param: '',
      icon: 'fas fa-film'
    }, {
      route: 'manga',
      param: '',
      icon: 'fas fa-book'
    }, {
      route: 'music',
      param: '',
      icon: 'fas fa-music'
    }, {
      route: 'list',
      param: '',
      icon: 'fas fa-bookmark'
    }];
  });
  let ApplicationController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class ApplicationController extends _EmberController {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: ApplicationController,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }, {
        kind: "method",
        key: "init",
        value: function init() {
          this._super(...arguments);

          this.set('landingPages', ['index', 'login', 'register', 'recover', 'oauth', 'page.cookies', 'page.about', 'page.subscribe', 'page.contact', 'page.dmca', 'page.policies', 'not-found']);
        }
      }, {
        kind: "field",
        decorators: [menuItems],
        key: "computed",
        value: void 0
      }]
    };
  }, Ember.Controller);
  _exports.default = ApplicationController;
});