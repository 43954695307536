define("socialchan/helpers/is-feature", ["exports", "socialchan/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isFeature = isFeature;
  _exports.default = void 0;

  function isFeature(ref) {
    return _environment.default.APP.features[ref] === 'true';
  }

  var _default = Ember.Helper.helper(isFeature);

  _exports.default = _default;
});