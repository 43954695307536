define("socialchan/services/push", ["exports", "socialchan/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PushService extends Ember.Service {
    init() {
      this._super(...arguments); // eslint-disable-next-line no-undef


      Push.Permission.request();
    }

    create(msg) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // eslint-disable-next-line no-undef
      return Push.create(msg, Object.assign(_environment.default.push, options));
    }

  }

  _exports.default = PushService;
});