define("socialchan/mixins/model-timestamps", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const attr = _emberData.default.attr;
  const ModelTimestamps = Ember.Mixin.create({
    created_at: attr('date', {
      defaultValue: () => {
        return new Date();
      }
    }),
    updated_at: attr('date')
  });
  var _default = ModelTimestamps;
  _exports.default = _default;
});