define("socialchan/routes/group/show/members", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GroupShowMembers extends Ember.Route.extend(_authenticatedRouteMixin.default) {
    model() {
      const show = this.modelFor('group.show');
      return Ember.get(show, 'group.members');
    }

  }

  _exports.default = GroupShowMembers;
});