define("socialchan/components/element/share-modal", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object"], function (_exports, _decorate2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let ElementShareModal = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class ElementShareModal extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: ElementShareModal,
      d: [{
        kind: "field",
        key: "isSelecting",

        value() {
          return true;
        }

      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changePostSelection",
        value: function changePostSelection() {
          this.set('isSelecting', false);
        }
      }]
    };
  }, Ember.Component);
  _exports.default = ElementShareModal;
});