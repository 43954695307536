define("socialchan/models/group-post", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-data", "@ember-decorators/data"], function (_exports, _decorate2, _emberData, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Model = _emberData.default.Model;
  let GroupPost = (0, _decorate2.default)(null, function (_initialize, _Model) {
    class GroupPost extends _Model {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: GroupPost,
      d: [{
        kind: "field",
        decorators: [(0, _data.belongsTo)('post')],
        key: "post",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.belongsTo)('group')],
        key: "group",
        value: void 0
      }]
    };
  }, Model);
  _exports.default = GroupPost;
});