define("socialchan/templates/components/layout/alert-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0+RlYGUt",
    "block": "{\"symbols\":[\"error\",\"key\"],\"statements\":[[7,\"div\"],[11,\"class\",\"relative bg-red-lightest border-l-4 border-red rounded text-red-dark px-4 py-3 mb-6\"],[11,\"role\",\"alert\"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"font-bold\"],[9],[0,\"\\n    \"],[1,[23,[\"errorMessage\",\"message\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"errorMessage\",\"errors\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[11,\"class\",\"\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"errorMessage\",\"errors\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"font-bold\"],[9],[1,[22,2,[]],false],[0,\":\"],[10],[0,\"\\n          \"],[1,[22,1,[]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/layout/alert-errors.hbs"
    }
  });

  _exports.default = _default;
});