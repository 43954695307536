define("socialchan/components/user/hovercard", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/component"], function (_exports, _decorate2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let UserHovercard = (0, _decorate2.default)([(0, _component.classNames)('relative flex flex-col')], function (_initialize, _EmberComponent) {
    class UserHovercard extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: UserHovercard,
      d: []
    };
  }, Ember.Component);
  _exports.default = UserHovercard;
});