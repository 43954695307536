define("socialchan/helpers/bg-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bgImage = bgImage;
  _exports.default = void 0;

  function bgImage(url) {
    let bgImage = `background-image: url('${url}');`;

    if (!Ember.String.isHTMLSafe(bgImage)) {
      bgImage = Ember.String.htmlSafe(bgImage);
    }

    return bgImage;
  }

  var _default = Ember.Helper.helper(bgImage);

  _exports.default = _default;
});