define("socialchan/serializers/user", ["exports", "socialchan/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserSerializer extends _application.default {
    constructor() {
      super(...arguments);
      this.attrs = {
        profile: {
          embedded: 'always'
        }
      };
    }

  }

  _exports.default = UserSerializer;
});