define("socialchan/routes/anime/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AnimeIndex extends Ember.Route.extend(_authenticatedRouteMixin.default) {
    model() {
      return Ember.RSVP.hash({
        animes: this.get('store').findAll('anime'),
        genres: this.get('store').query('genre', {
          music: false
        })
      });
    }

  }

  _exports.default = AnimeIndex;
});