define("socialchan/components/post/kokoro-button", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object", "ember-concurrency-decorators"], function (_exports, _decorate2, _service, _object, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let PostKokoroButton = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class PostKokoroButton extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: PostKokoroButton,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "ajax",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          enqueue: true
        })],
        key: "kokoroPost",

        value() {
          return function* (id) {
            // TODO: One place ajax URLs
            yield this.get('ajax').put(`posts/${id}/kokoro`).then(response => {
              return this.set('post.kokoros_count', response);
            });
            yield this.get('post').incrementProperty('kokoros_count');
          };
        }

      }, {
        kind: "method",
        decorators: [_object.action],
        key: "toggleKokoro",
        value: function toggleKokoro(id) {
          if (!this.kokoroPost.isRunning) {
            this.kokoroPost.perform(id);
            this.get('post').toggleProperty('isKokored');
          }
        }
      }]
    };
  }, Ember.Component);
  _exports.default = PostKokoroButton;
});