define("socialchan/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const JSONAPISerializer = _emberData.default.JSONAPISerializer;

  class ApplicationSerializer extends JSONAPISerializer {
    keyForAttribute(key) {
      return key;
    }

  }

  _exports.default = ApplicationSerializer;
});