define("socialchan/routes/group/show", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-simple-auth/mixins/authenticated-route-mixin", "@ember-decorators/service", "@ember-decorators/object", "ember-data"], function (_exports, _decorate2, _authenticatedRouteMixin, _service, _object, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NotFoundError = _emberData.default.NotFoundError;
  let GroupShow = (0, _decorate2.default)(null, function (_initialize, _EmberRoute$extend) {
    class GroupShow extends _EmberRoute$extend {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: GroupShow,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "method",
        key: "model",
        value: function model(params) {
          return Ember.RSVP.hash({
            group: Ember.get(this, 'store').query('group', {
              id: params.group_id,
              include: 'author,author.profile'
            })
          });
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "error",
        value: function error(_error) {
          if (_error instanceof NotFoundError) {
            // redirect to a list of all posts instead
            this.transitionTo('not-found');
          } else {
            // otherwise let the error bubble
            return true;
          }
        }
      }]
    };
  }, Ember.Route.extend(_authenticatedRouteMixin.default));
  _exports.default = GroupShow;
});