define("socialchan/templates/components/layout/right-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1XhHH0+s",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"bg-indigo-darkest flex-none p-4 h-full select-none scrolling-auto overflow-auto z-50 hidden md:block\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"user\",[23,[\"auth\",\"user\",\"id\"]]],[[\"class\"],[\"block opacity-75 no-underline mb-4\"]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"bg-white h-12 w-12 flex items-center justify-center text-black text-2xl font-semibold rounded-full mb-1 overflow-hidden\"],[9],[0,\"\\n      \"],[7,\"img\"],[12,\"src\",[28,[[23,[\"auth\",\"user\",\"profile\",\"avatar\"]]]]],[11,\"alt\",\"\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-center text-white text-sm\"],[9],[0,\"\\n      Perfil\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"cursor-pointer mb-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"bg-indigo-lightest opacity-75 h-12 w-12 flex items-center justify-center text-black text-2xl font-semibold rounded-full overflow-hidden\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/img/user_default.jpg\"],[11,\"alt\",\"\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"cursor-pointer mb-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"bg-indigo-lightest opacity-75 h-12 w-12 flex items-center justify-center text-black text-2xl font-semibold rounded-full overflow-hidden\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/img/user_default.jpg\"],[11,\"alt\",\"\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"cursor-pointer\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"bg-white opacity-25 h-12 w-12 flex items-center justify-center text-black text-2xl font-semibold rounded-full mb-1 overflow-hidden\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fas fa-plus\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/layout/right-sidebar.hbs"
    }
  });

  _exports.default = _default;
});