define("socialchan/components/media/item-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MediaItemCard extends Ember.Component {}

  _exports.default = MediaItemCard;
});