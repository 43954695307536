define("socialchan/components/page/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PageFooter extends Ember.Component {}

  _exports.default = PageFooter;
});