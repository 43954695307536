define("socialchan/components/post/create", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "ember-concurrency-decorators", "@ember-decorators/object"], function (_exports, _decorate2, _service, _emberConcurrencyDecorators, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let PostCreate = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class PostCreate extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: PostCreate,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "store",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "push",
        value: void 0
      }, {
        kind: "field",
        key: "content",

        value() {
          return '';
        }

      }, {
        kind: "field",
        key: "isSpoiler",

        value() {
          return false;
        }

      }, {
        kind: "method",
        decorators: [_emberConcurrencyDecorators.task],
        key: "doPublish",
        value: function* doPublish() {
          const _this$getProperties = this.getProperties('content'),
                content = _this$getProperties.content;

          let post = this.get('store').createRecord('post', {
            author: this.get('auth.user'),
            content: content
          });
          this.set('content', '');
          yield post.save();
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "wrap",
        value: function wrap(text) {
          // console.log(this.toString());
          this.get('push').create('Test de notificación', {
            icon: '/img/user_default.jpg'
          });
          this.set('content', this.get('content') + ' ' + text);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "spoiler",
        value: function spoiler() {
          this.toggleProperty('isSpoiler');
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "info",
        value: function info() {
          let newwindow = window.open(`${Ember.Router.rootURL}about`, 'gugul', 'height=200,width=150');
          if (window.focus) newwindow.focus();
          return false;
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "createPost",
        value: function createPost() {
          this.get('doPublish').perform();
        }
      }]
    };
  }, Ember.Component);
  _exports.default = PostCreate;
});