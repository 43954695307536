define("socialchan/templates/recover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xk/BYYR1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"h-screen overflow-hidden bg-cover bg-fixed bg-center bg-no-repeat\"],[11,\"style\",\"background-image:linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url('/img/landing/img02.jpg')\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"w-full max-w-xs mx-auto mt-8\"],[9],[0,\"\\n    \"],[7,\"form\"],[11,\"class\",\"bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"mb-4\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"block text-grey-darker text-sm font-bold mb-2\"],[11,\"for\",\"identification\"],[9],[0,\"\\n          Email o nombre de usuario\\n        \"],[10],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"id\",\"class\",\"placeholder\",\"value\"],[\"identification\",\"shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker\",\"Email o nombre de usuario\",[23,[\"identification\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"flex items-center justify-between\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"bg-indigo-dark hover:bg-indigo-darker text-white font-bold py-2 px-4 rounded\"],[11,\"type\",\"submit\"],[9],[0,\"\\n          Recuperar\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"recover\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-center text-white text-shadow text-xs\"],[9],[0,\"\\n      © 2018 Social-chan. All rights reserved.\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/recover.hbs"
    }
  });

  _exports.default = _default;
});