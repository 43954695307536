define("socialchan/templates/components/post/kokoro-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CXSJS/En",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"bg-indigo-dark hover:bg-indigo-darker rounded-full p-3 ml-3\"],[9],[0,\"\\n  \"],[7,\"i\"],[12,\"class\",[28,[\"fill-current \",[27,\"if\",[[23,[\"isKokored\"]],\"fas text-red\",\"far text-white\"],null],\" fa-heart fa-lg\"]]],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"toggleKokoro\",[23,[\"post\",\"id\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/post/kokoro-button.hbs"
    }
  });

  _exports.default = _default;
});