define("socialchan/controllers/login", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object", "ember-concurrency-decorators"], function (_exports, _decorate2, _service, _object, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LoginController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class LoginController extends _EmberController {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: LoginController,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          drop: true
        })],
        key: "authenticate",
        value: function* authenticate() {
          const _this$getProperties = this.getProperties('identification', 'password'),
                identification = _this$getProperties.identification,
                password = _this$getProperties.password;

          yield this.get('session').authenticate('authenticator:oauth2', identification, password, ['*'], {
            'Content-Type': 'application/json'
          }).catch(error => {
            this.set('errorMessage', error);
          }).then(() => {
            // TODO: GA metrics event
            // get(this, 'metrics').trackEvent(metrics);
            this.transitionToRoute('feed');
          });
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "login",
        value: function login() {
          this.get('authenticate').perform();
        }
      }]
    };
  }, Ember.Controller);
  _exports.default = LoginController;
});