define("socialchan/components/comment/single", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service"], function (_exports, _decorate2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { computed } from '@ember-decorators/object'
  // import { w } from '@ember/string'
  let CommentSingle = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class CommentSingle extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: CommentSingle,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }]
    };
  }, Ember.Component);
  _exports.default = CommentSingle;
});