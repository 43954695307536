define("socialchan/components/group/join-button", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object", "ember-concurrency-decorators"], function (_exports, _decorate2, _service, _object, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let GroupJoinButton = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class GroupJoinButton extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: GroupJoinButton,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "ajax",
        value: void 0
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          drop: true
        })],
        key: "postJoin",
        value: function* postJoin(id) {
          yield this.get('ajax').post(`group/${id}/join`);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "join",
        value: function join(id) {
          this.get('postJoin').perform(id);
          this.toggleProperty('isMember');
        }
      }]
    };
  }, Ember.Component);
  _exports.default = GroupJoinButton;
});