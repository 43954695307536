define("socialchan/models/profile", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-data", "@ember-decorators/data"], function (_exports, _decorate2, _emberData, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Model = _emberData.default.Model;
  let Profile = (0, _decorate2.default)(null, function (_initialize, _Model) {
    class Profile extends _Model {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: Profile,
      d: [{
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "name",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "surname",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "bio",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string', {
          defaultValue() {
            return '/img/user_default.jpg';
          }

        })],
        key: "avatar",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "country",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string', {
          defaultValue() {
            return '/img/chat_bg.png';
          }

        })],
        key: "bg_image",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('date')],
        key: "birthday",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('boolean')],
        key: "gender",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.belongsTo)('post')],
        key: "sticky",
        value: void 0
      }]
    };
  }, Model);
  _exports.default = Profile;
});