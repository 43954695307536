define("socialchan/components/user/header", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object"], function (_exports, _decorate2, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let UserHeader = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class UserHeader extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: UserHeader,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "logout",
        value: function logout() {
          this.get('session').invalidate();
        }
      }]
    };
  }, Ember.Component);
  _exports.default = UserHeader;
});