define("socialchan/mirage/factories/group-type", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: _emberCliMirage.faker.random.word,
    icon: _emberCliMirage.faker.random.arrayElement(['fas fa-home', 'fas fa-paint-brush', 'fas fa-pepper-hot', 'fas fa-pen-nib']),
    color: _emberCliMirage.faker.random.arrayElement(['indigo', 'purple', 'green', 'grey', 'blue'])
  });

  _exports.default = _default;
});