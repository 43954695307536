define("socialchan/mirage/factories/group", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: _emberCliMirage.faker.lorem.words,
    slug: _emberCliMirage.faker.lorem.slug,
    image: _emberCliMirage.faker.random.image,
    // cover: faker.image.imageUrl(256, 96),
    about: _emberCliMirage.faker.lorem.sentence,
    groupType: (0, _emberCliMirage.association)(),
    author: (0, _emberCliMirage.association)()
  });

  _exports.default = _default;
});