define("socialchan/templates/components/feed/invite-friends", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZtGkIdim",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"block bg-indigo text-white text-shadow shadow rounded px-4 py-8 mb-3 bg-contain bg-center bg-no-repeat\"],[11,\"style\",\"background-image:url('/img/illustrations/undraw_opened.svg')\"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"text-lg font-bold mb-2\"],[9],[0,\"\\n    Invita a tus amigos\\n  \"],[10],[0,\"\\n  Invita a todos tus amigos y empieza a compartir anime y manga con ellos.\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/feed/invite-friends.hbs"
    }
  });

  _exports.default = _default;
});