define("socialchan/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "ember-ajax/mixins/ajax-support", "socialchan/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _ajaxSupport, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const JSONAPIAdapter = _emberData.default.JSONAPIAdapter;

  class ApplicationAdapter extends JSONAPIAdapter.extend(_dataAdapterMixin.default, _ajaxSupport.default) {
    init() {
      this._super(...arguments);

      this.setProperties({
        authorizer: 'authorizer:application',
        host: _environment.default.APP.apiHost,
        headers: {
          'Content-Type': 'application/json'
        }
      });
    }

    authorize(xhr) {
      let _this$get = this.get('session.data.authenticated'),
          access_token = _this$get.access_token;

      xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
    }

  }

  _exports.default = ApplicationAdapter;
});