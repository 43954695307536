define("socialchan/mirage/factories/comment", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    content: _emberCliMirage.faker.lorem.paragraph,
    author: (0, _emberCliMirage.association)()
  });

  _exports.default = _default;
});