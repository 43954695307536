define("socialchan/templates/components/comment/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u1NkXQy8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"flex items-center mt-2\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"class\",\"flex-no-shrink w-8 h-8 bg-grey rounded-full mr-3\"],[12,\"src\",[23,[\"auth\",\"user\",\"profile\",\"avatar\"]]],[11,\"alt\",\"\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inline-block relative flex items-center w-full rounded-full\"],[9],[0,\"\\n    \"],[1,[27,\"textarea\",null,[[\"value\",\"rows\",\"class\",\"placeholder\",\"keyDown\"],[[23,[\"content\"]],\"1\",\"appearance-none w-full bg-white text-grey-dark shadow rounded-full px-4 py-2 pr-9 resize-none\",\"Escribe un comentario...\",[27,\"action\",[[22,0,[]],\"handleSubmit\"],null]]]],false],[0,\"\\n\"],[0,\"    \"],[7,\"div\"],[11,\"class\",\"absolute pin-y pin-r flex items-center pr-4\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"text-grey-dark mr-2\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"far fa-smile fill-current\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"text-grey-dark\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fas fa-camera fill-current\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"create\"],[[\"on\"],[\"submit\"]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/comment/create.hbs"
    }
  });

  _exports.default = _default;
});