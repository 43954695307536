define("socialchan/templates/components/element/share-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g1Lql0pw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fixed pin z-50 overflow-auto bg-smoke-light flex\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fixed max-w-md md:relative pin-b pin-x align-top m-auto justify-end md:justify-center p-8 bg-white md:rounded w-full md:h-auto md:shadow flex\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isSelecting\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"bg-grey-light hover:bg-grey text-grey-darker rounded p-4\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"far fa-comments fa-3x\"],[9],[10],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"changePostSelection\"]],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"bg-grey-light hover:bg-grey text-grey-darker rounded p-4 mx-2\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fas fa-film fa-3x\"],[9],[10],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"changePostSelection\"]],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"bg-grey-light hover:bg-grey text-grey-darker rounded p-4 mx-2\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fas fa-book fa-3x\"],[9],[10],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"changePostSelection\"]],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"bg-grey-light hover:bg-grey text-grey-darker rounded p-4 mx-2\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fas fa-music fa-3x\"],[9],[10],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"changePostSelection\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"textarea\"],[11,\"class\",\"appearance-none bg-grey-light w-full\"],[11,\"rows\",\"5\"],[9],[10],[0,\"\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/element/share-modal.hbs"
    }
  });

  _exports.default = _default;
});