define("socialchan/services/ajax", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-ajax/services/ajax", "@ember-decorators/service", "socialchan/config/environment", "@ember-decorators/object"], function (_exports, _decorate2, _ajax, _service, _environment, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AjaxAppService = (0, _decorate2.default)(null, function (_initialize, _AjaxService) {
    class AjaxAppService extends _AjaxService {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: AjaxAppService,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "field",
        key: "host",

        value() {
          return _environment.default.APP.apiHost;
        }

      }, {
        kind: "get",
        decorators: [(0, _object.computed)('session.isAuthenticated').volatile()],
        key: "headers",
        value: function headers() {
          const session = Ember.get(this, 'session');
          let headers = {};
          headers['App-Pragma'] = 'no-cache';

          if (Ember.get(session, 'isAuthenticated')) {
            session.authorize('authorizer:application', (headerName, headerValue) => {
              headers[headerName] = headerValue;
            });
          }

          return headers;
        }
      }]
    };
  }, _ajax.default);
  _exports.default = AjaxAppService;
});