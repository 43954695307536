define("socialchan/components/element/signup-form", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "ember-concurrency-decorators", "@ember-decorators/object"], function (_exports, _decorate2, _service, _emberConcurrencyDecorators, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { isAjaxError } from 'ember-ajax/errors';
  let ElementSignupForm = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class ElementSignupForm extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: ElementSignupForm,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "ajax",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "router",
        value: void 0
      }, {
        kind: "field",
        key: "errorMessage",

        value() {
          return null;
        }

      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          drop: true
        })],
        key: "createUser",
        value: // TODO: Better way to
        function* createUser() {
          const formData = this.getProperties(['name', 'surname', 'email', 'nickname', 'password']);
          yield this.get('ajax').post('user', {
            data: {
              email: formData.email,
              nickname: formData.nickname,
              password: formData.password,
              profile: {
                name: formData.name,
                surname: formData.surname
              }
            }
          }).then(() => {
            this.get('router').transitionTo('login');
          }).catch(error => {
            this.set('errorMessage', error.payload);
          });
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "register",
        value: function register() {
          this.get('createUser').perform();
        }
      }]
    };
  }, Ember.Component);
  _exports.default = ElementSignupForm;
});