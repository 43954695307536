define("socialchan/routes/feed", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Feed extends Ember.Route.extend(_authenticatedRouteMixin.default) {
    model() {
      return Ember.RSVP.hash({
        posts: this.get('store').query('post', {
          include: 'author,author.profile',
          append: 'kokoros_count'
        }),
        populars: this.get('store').query('user', {
          page: {
            limit: 5
          },
          include: 'profile'
        })
      });
    }

  }

  _exports.default = Feed;
});