define("socialchan/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { inject as service } from '@ember/service';
  // import { task } from 'ember-concurrency';
  class IndexController extends Ember.Controller {
    // session: service(),
    init() {
      this._super(...arguments);

      this.set('swiperOptions', {
        mousewheelControl: true
      });
    }

  }

  _exports.default = IndexController;
});