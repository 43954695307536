define("socialchan/router", ["exports", "socialchan/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.on('routeDidChange', transition => {
        this._trackPage();
      });
    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = this.get('url');
        const title = this.getWithDefault('currentRouteName', 'unknown');
        Ember.get(this, 'metrics').trackPage({
          page,
          title
        });
      });
    }

  });
  Router.map(function () {
    this.route('login');
    this.route('recover');
    this.route('page', function () {
      this.route('cookies');
      this.route('about');
      this.route('dmca');
      this.route('policies');
      this.route('subscribe');
      this.route('contact');
    });
    this.route('anime', function () {
      this.route('show', {
        path: '/:anime_id'
      });
    });
    this.route('manga');
    this.route('group', function () {
      this.route('found');
      this.route('show', {
        path: '/:group_id'
      }, function () {
        this.route('posts', {
          path: '/'
        });
        this.route('members');
        this.route('settings');
      });
    });
    this.route('user', {
      path: '/user/:user_id'
    }, function () {
      this.route('posts', {
        path: '/'
      });
      this.route('groups');
      this.route('anime');
      this.route('manga');
      this.route('music');
      this.route('friends');
    });
    this.route('list');
    this.route('music');
    this.route('settings', function () {
      this.route('privacy');
      this.route('removal');
    });
    this.route('feed');
    this.route('not-found', {
      path: '/*path'
    });
    this.route('search');
    this.route('chat');
    this.route('register');
  });
  var _default = Router;
  _exports.default = _default;
});