define("socialchan/routes/anime/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AnimeShow extends Ember.Route {
    model(params) {
      const peek = this.get('store').peekRecord('anime', params.anime_id);

      if (peek !== null) {
        return peek;
      } else {
        return this.get('store').findRecord('anime', params.anime_id);
      }
    }

  }

  _exports.default = AnimeShow;
});