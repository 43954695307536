define("socialchan/templates/components/layout/left-sub-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8lcdFPEn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"overflow-hidden bg-indigo-darker text-white flex-none w-64 z-40 hidden md:block h-full\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"activeSection\"]],\"monitor\"],null]],null,{\"statements\":[[0,\"    \"],[1,[21,\"layout/sidebar-monitor\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"activeSection\"]],\"search\"],null]],null,{\"statements\":[[0,\"    \"],[1,[21,\"layout/sidebar-search\"],false],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/layout/left-sub-sidebar.hbs"
    }
  });

  _exports.default = _default;
});