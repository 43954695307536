define("socialchan/services/-observer-admin", ["exports", "ember-in-viewport/services/-observer-admin"], function (_exports, _observerAdmin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _observerAdmin.default;
    }
  });
});