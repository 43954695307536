define("socialchan/templates/components/user/hovercard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4l5F86nK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"w-full h-18 rounded-t-lg bg-grey-light bg-cover bg-no-repeat\"],[12,\"style\",[27,\"bg-image\",[[23,[\"user\",\"profile\",\"bg_image\"]]],null]],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"absolute pin flex items-center justify-between px-3\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[23,[\"user\",\"profile\",\"avatar\"]]],[11,\"class\",\"w-16 h-16 bg-grey rounded-full border-white border-2\"],[11,\"alt\",\"\"],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"user\",\"isLoggedIn\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,[\"user\",\"following\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"self-center shadow bg-white rounded-full text-indigo-darker text-sm p-2 ml-2\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fas fa-user-times\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"self-center shadow bg-white rounded-full text-indigo-darker text-sm p-2\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fas fa-comment-alt\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"self-center shadow bg-white rounded-full text-indigo-darker text-sm p-2 ml-2\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fas fa-user-plus\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"truncate px-3 py-4 mt-6\"],[9],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"inline text-black font-normal font-sans\"],[9],[0,\"\\n    \"],[1,[23,[\"user\",\"nickname\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"inline font-sans font-light truncate text-sm text-grey-dark\"],[9],[0,\"\\n    \"],[1,[23,[\"user\",\"profile\",\"bio\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/user/hovercard.hbs"
    }
  });

  _exports.default = _default;
});