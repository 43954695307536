define("socialchan/models/genre", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-data", "@ember-decorators/data"], function (_exports, _decorate2, _emberData, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Model = _emberData.default.Model;
  let Genre = (0, _decorate2.default)(null, function (_initialize, _Model) {
    class Genre extends _Model {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: Genre,
      d: [{
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "name",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "color",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('boolean')],
        key: "music",
        value: void 0
      }]
    };
  }, Model);
  _exports.default = Genre;
});