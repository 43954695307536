define("socialchan/components/layout/top-sidebar", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/component", "@ember-decorators/service", "@ember-decorators/object"], function (_exports, _decorate2, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let menuItems = (0, _object.computed)('auth.user.id', function () {
    const user = this.get('auth.user.id');
    return [{
      route: 'user',
      param: user,
      icon: 'fas fa-user'
    }, {
      route: 'feed',
      param: '',
      icon: 'fas fa-comments'
    }, {
      route: 'group',
      param: '',
      icon: 'fas fa-users'
    }, {
      route: 'anime',
      param: '',
      icon: 'fas fa-film'
    }, {
      route: 'manga',
      param: '',
      icon: 'fas fa-book'
    }, {
      route: 'music',
      param: '',
      icon: 'fas fa-music'
    }, {
      route: 'list',
      param: '',
      icon: 'fas fa-bookmark'
    }];
  });
  let TopSidebarComponent = (0, _decorate2.default)([(0, _component.classNames)('flex justify-center items-center w-full bg-indigo-darkest select-none scrolling-auto overflow-hidden z-50')], function (_initialize, _EmberComponent) {
    class TopSidebarComponent extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: TopSidebarComponent,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }, {
        kind: "field",
        decorators: [menuItems],
        key: "computed",
        value: void 0
      }]
    };
  }, Ember.Component);
  _exports.default = TopSidebarComponent;
});