define("socialchan/services/auth", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service"], function (_exports, _decorate2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AuthService = (0, _decorate2.default)(null, function (_initialize, _EmberService) {
    class AuthService extends _EmberService {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: AuthService,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "store",
        value: void 0
      }, {
        kind: "method",
        key: "load",
        value: function load() {
          if (this.get('session.isAuthenticated')) {
            return this.get('store').queryRecord('user', {
              me: true
            }).then(user => {
              this.set('user', user);
            });
          } else {
            return Ember.RSVP.resolve();
          }
        }
      }]
    };
  }, Ember.Service);
  _exports.default = AuthService;
});