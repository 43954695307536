define("socialchan/components/user/follow-button", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "ember-concurrency-decorators", "@ember-decorators/object"], function (_exports, _decorate2, _service, _emberConcurrencyDecorators, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let UserFollowButton = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class UserFollowButton extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: UserFollowButton,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "ajax",
        value: void 0
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          drop: true
        })],
        key: "followUser",
        value: function* followUser(id) {
          yield this.get('ajax').post(`user/${id}/follow`);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "toggleFollow",
        value: function toggleFollow(id) {
          this.get('followUser').perform(id);
          this.toggleProperty('isFollowed');
        }
      }]
    };
  }, Ember.Component);
  _exports.default = UserFollowButton;
});