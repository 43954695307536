define("socialchan/mirage/factories/user", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    nickname: _emberCliMirage.faker.internet.userName,
    email: _emberCliMirage.faker.internet.exampleEmail,
    active: true,
    created_at: _emberCliMirage.faker.date.past,
    updated_at: _emberCliMirage.faker.date.past,
    profile: (0, _emberCliMirage.association)()
  });

  _exports.default = _default;
});