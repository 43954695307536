define("socialchan/templates/user/posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qHzoyeZR",
    "block": "{\"symbols\":[\"post\"],\"statements\":[[4,\"if\",[[27,\"or\",[[23,[\"model\",\"user\",\"following\"]],[23,[\"model\",\"user\",\"isLoggedIn\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[\"post/create\"],[[\"placeholder\"],[[27,\"concat\",[\"Publicar en el perfil de @\",[23,[\"model\",\"user\",\"nickname\"]],\"...\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[23,[\"model\",\"posts\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[\"post/single\"],[[\"post\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/user/posts.hbs"
    }
  });

  _exports.default = _default;
});