define("socialchan/templates/components/page/alt-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wb3wUgzA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\"],[11,\"class\",\"fixed pin-x pin-b flex items-center md:justify-center border-b-8 border-indigo list-reset overflow-auto scrolling-touch pb-4 px-3\"],[9],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"px-2\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"https://dojo.moe\"],[11,\"target\",\"_blank\"],[9],[0,\"\\n      Dojo\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"px-2\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"page.subscribe\"],null,{\"statements\":[[0,\"      Suscripción\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"px-2\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"https://devs.social-chan.com\"],[11,\"target\",\"_blank\"],[9],[0,\"\\n      Desarrolladores\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"px-2\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"page.cookies\"],null,{\"statements\":[[0,\"      Cookies\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"px-2\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"page.policies\"],null,{\"statements\":[[0,\"      Condiciones\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"px-2\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"page.contact\"],null,{\"statements\":[[0,\"      Contacto\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"px-2\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"page.about\"],null,{\"statements\":[[0,\"      Acerca\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/page/alt-footer.hbs"
    }
  });

  _exports.default = _default;
});