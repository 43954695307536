define("socialchan/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    mobile: 'screen and (max-width: 768px)',
    tablet: 'screen and (max-width: 1200px)',
    desktop: 'screen and (max-width: 1440px)'
  };
  _exports.default = _default;
});