define("socialchan/routes/index", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "socialchan/config/environment"], function (_exports, _decorate2, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Index = (0, _decorate2.default)(null, function (_initialize, _EmberRoute) {
    class Index extends _EmberRoute {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: Index,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "method",
        key: "redirect",
        value: function redirect() {
          if (Ember.get(this, 'session.isAuthenticated')) this.transitionTo('feed');
        }
      }, {
        kind: "field",
        key: "headTags",

        value() {
          return function () {
            const obj = _environment.default.APP.meta;
            let arr = [];

            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                const element = obj[key];
                arr.push({
                  type: 'meta',
                  tagId: `meta-${key}-tag`,
                  attrs: {
                    name: key,
                    content: element
                  }
                });
              }
            }

            return arr;
          };
        }

      }]
    };
  }, Ember.Route);
  _exports.default = Index;
});