define("socialchan/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F3WCFwJH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"bg-indigo-light text-white py-8 mb-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container mx-auto text-center\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"Recurso no encontrado\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"container mx-auto\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-orange-lightest border-l-4 border-orange text-orange-dark p-4 mt-4\"],[11,\"role\",\"alert\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"font-bold\"],[9],[0,\"Error\"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      No se ha podido encontrar el recurso que esta solicitando, por favor, compruebe que la ruta\\n      URL introducida es correcta.\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});