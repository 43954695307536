define("socialchan/components/layout/left-sidebar", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object", "@ember-decorators/component"], function (_exports, _decorate2, _service, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LayoutLeftSidebar = (0, _decorate2.default)([(0, _component.classNames)('h-full overflow-hidden')], function (_initialize, _EmberComponent) {
    class LayoutLeftSidebar extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: LayoutLeftSidebar,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }, {
        kind: "field",
        key: "isSubsidebarActive",

        value() {
          return false;
        }

      }, {
        kind: "field",
        key: "activeSection",

        value() {
          return '';
        }

      }, {
        kind: "method",
        decorators: [_object.action],
        key: "toggleSubsidebar",
        value: function toggleSubsidebar(section) {
          if (Ember.get(this, 'activeSection') === section) Ember.set(this, 'activeSection', '');
          if (Ember.get(this, 'activeSection') === '') this.toggleProperty('isSubsidebarActive');
          if (Ember.get(this, 'isSubsidebarActive') && Ember.get(this, 'activeSection') !== section) Ember.set(this, 'activeSection', section);
        }
      }]
    };
  }, Ember.Component);
  _exports.default = LayoutLeftSidebar;
});