define("socialchan/templates/page/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IhPCV/oZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"bg-indigo-light text-white py-8 mb-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container mx-auto text-center\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"Contacto\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"container mx-auto py-2 px-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-orange-lightest border-l-4 border-orange text-orange-dark p-4\"],[11,\"role\",\"alert\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"font-bold\"],[9],[0,\"Aviso importante\"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      Del mismo modo, pedimos que si creen encontrar contenido que infrinja los\\n      derechos de autor reporten ese contenido directamente desde la plataforma\\n      o enviando un mail a \"],[7,\"a\"],[11,\"href\",\"mailto:dmca@social-chan.com\"],[9],[0,\"dmca@social-chan.com\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"bg-orange-lightest border-l-4 border-orange text-orange-dark p-4 mt-4\"],[11,\"role\",\"alert\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"font-bold\"],[9],[0,\"Important announcement\"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      In the same way, we ask that if you believe that you find\\n      content that infringes copyright you report that content directly from\\n      the platform or by sending an email to \"],[7,\"a\"],[11,\"href\",\"mailto:dmca@social-chan.com\"],[9],[0,\"dmca@social-chan.com\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"my-1\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/page/contact.hbs"
    }
  });

  _exports.default = _default;
});