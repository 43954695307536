define("socialchan/templates/settings/removal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ePWYn8Jj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[9],[0,\"\\n  ¿Estás realmente seguro de que deseas eliminar tu cuenta\\n  \"],[7,\"strong\"],[9],[0,\"permanentemente\"],[10],[0,\" de Social-chan?\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"bg-red-dark hover:bg-red-darker text-white font-bold py-2 px-4 rounded\"],[9],[0,\"\\n    Eliminar\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/settings/removal.hbs"
    }
  });

  _exports.default = _default;
});