define("socialchan/mirage/config", ["exports", "ember-cli-mirage/response", "socialchan/config/environment"], function (_exports, _response, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const TOKEN = 'abcdefghijklmnopqrstuvwxyz';

  function getQueryVariable(query, item) {
    var vars = query.split('&');

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');

      if (decodeURIComponent(pair[0]) == item) {
        return decodeURIComponent(pair[1]);
      }
    }

    return;
  }

  function responseError(error, msg) {
    let code = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 422;
    return new _response.default(code, {
      'Content-Type': 'application/json'
    }, {
      error: error,
      message: msg
    });
  }

  function _default() {
    this.passthrough();
    this.urlPrefix = _environment.default.APP.apiHost;
    this.timing = 300;
    this.post('/oauth/token', (schema, request) => {
      const query = request.requestBody;
      let username = getQueryVariable(query, 'username');
      let password = getQueryVariable(query, 'password');

      if (!username || username === 'undefined') {
        return responseError('invalid_credentials', 'username cannot be blank');
      }

      if (!password || password === 'undefined') {
        return responseError('invalid_credentials', 'password cannot be blank');
      }

      return new _response.default(200, {
        'Content-Type': 'application/json'
      }, {
        access_token: TOKEN,
        token_type: 'Bearer',
        expires_in: new Date().getTime(),
        refresh_token: TOKEN,
        scope: '*'
      });
    });
    this.get('/users', (_ref, request) => {
      let users = _ref.users;
      const query = request.queryParams;
      return users.all().slice(query['page[offset]'] || 0, query['page[limit]'] || 5);
    });
    this.get('/user/me', (_ref2, request) => {
      let users = _ref2.users;
      return users.find(1);
    });
    this.resource('user', {
      except: ['index'],
      path: '/users'
    });
    this.resource('post', {
      path: '/posts'
    });
    this.put('posts/:id/kokoro', (_ref3, request) => {
      let posts = _ref3.posts;
      let post = posts.find(request.params.id);
      post.kokoro_count++;
      post.save();
    });
    this.resource('comment', {
      path: '/comments'
    });
    this.resource('group', {
      path: '/groups'
    });
    this.resource('group-type', {
      path: '/group-types'
    }); // this.resource('group-member', { path: '/group' })
  }
});