define("socialchan/routes/user/groups", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserGroups extends Ember.Route.extend(_authenticatedRouteMixin.default) {
    model() {
      let user = this.modelFor('user');
      return this.get('store').query('group', {
        include: 'author,author.profile,group-type',
        member: user.get('id')
      });
    }

  }

  _exports.default = UserGroups;
});