define("socialchan/components/comment/create", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object", "ember-concurrency-decorators"], function (_exports, _decorate2, _service, _object, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let CommentCreate = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class CommentCreate extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: CommentCreate,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "store",
        value: void 0
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          drop: true
        })],
        key: "createComment",
        value: function* createComment() {
          const _this$getProperties = this.getProperties('content'),
                content = _this$getProperties.content;

          let post = this.get('store').peekRecord('post', this.get('post.id'));
          let comment = this.get('store').createRecord('comment', {
            post_id: post.id,
            author: this.get('auth.user'),
            content: content
          });
          this.set('content', '');
          yield post.get('comments').pushObject(comment);
          yield comment.save(); // TODO: Move to model as computed (counting related comments loaded)

          yield post.incrementProperty('comments_count');
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "create",
        value: function create() {
          this.get('createComment').perform();
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "handleSubmit",
        value: function handleSubmit(ev) {
          if (ev.metaKey && ev.keyCode === 13) {
            this.create();
          }
        }
      }]
    };
  }, Ember.Component);
  _exports.default = CommentCreate;
});