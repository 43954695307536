define("socialchan/templates/components/media/item-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WhItF/U6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"bg-red rounded overflow-hidden\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"class\",\"w-full\"],[12,\"src\",[23,[\"item\",\"image\"]]],[11,\"alt\",\"\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/media/item-card.hbs"
    }
  });

  _exports.default = _default;
});