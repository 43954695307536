define("socialchan/models/comment", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-data", "@ember-decorators/data", "socialchan/mixins/model-timestamps"], function (_exports, _decorate2, _emberData, _data, _modelTimestamps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Model = _emberData.default.Model;
  let Comment = (0, _decorate2.default)(null, function (_initialize, _Model$extend) {
    class Comment extends _Model$extend {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: Comment,
      d: [{
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "content",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.belongsTo)('user')],
        key: "author",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.belongsTo)('post', {
          inverse: 'comments'
        })],
        key: "post",
        value: void 0
      }]
    };
  }, Model.extend(_modelTimestamps.default));
  _exports.default = Comment;
});