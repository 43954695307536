define("socialchan/models/post", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-data", "@ember-decorators/data", "@ember-decorators/service", "@ember-decorators/object/computed", "socialchan/mixins/model-timestamps"], function (_exports, _decorate2, _emberData, _data, _service, _computed, _modelTimestamps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Model = _emberData.default.Model;
  let Post = (0, _decorate2.default)(null, function (_initialize, _Model$extend) {
    class Post extends _Model$extend {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: Post,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "content",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('number', {
          defaultValue: 0
        })],
        key: "comments_count",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('number', {
          defaultValue: 0
        })],
        key: "kokoros_count",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('boolean', {
          defaultValue: false
        })],
        key: "isKokored",
        value: void 0
      }, {
        kind: "field",
        key: "isSticky",

        value() {
          return false;
        }

      }, {
        kind: "field",
        decorators: [(0, _data.belongsTo)('user')],
        key: "author",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.hasMany)('comment', {
          inverse: 'post'
        })],
        key: "comments",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _computed.notEmpty)('comments')],
        key: "hasComments",
        value: void 0
      }]
    };
  }, Model.extend(_modelTimestamps.default));
  _exports.default = Post;
});