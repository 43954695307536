define("socialchan/components/layout/sidebar-publish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LayoutSidebarPublish extends Ember.Component {// createPost: task(function* () {
    //   const formData = this.getProperties([
    //     'content',
    //   ]);
    //   let user = this.get('store').createRecord('post', {
    //     content: formData.content,
    //   });
    //   yield user.save().catch((reason) => {
    //     this.set('errorMessage', reason.error);
    //   }).then(() => {
    //     this.transitionToRoute('feed');
    //   });
    // }).drop(),
    // actions: {
    //   create() {
    //     this.get('createPost').perform();
    //   }
    // }
  }

  _exports.default = LayoutSidebarPublish;
});