define("socialchan/routes/group/show/posts", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      let show = this.modelFor('group.show');
      return Ember.RSVP.hash({
        group: Ember.get(show, 'group'),
        posts: Ember.get(this, 'store').query('post', {
          group_id: Ember.get(show, 'group.id')
        })
      });
    }

  });

  _exports.default = _default;
});