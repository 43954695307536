define("socialchan/components/feed/user-single", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/component"], function (_exports, _decorate2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let FeedUserSingle = (0, _decorate2.default)([(0, _component.tagName)('li'), (0, _component.classNames)('inline-flex items-center my-2')], function (_initialize, _EmberComponent) {
    class FeedUserSingle extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: FeedUserSingle,
      d: []
    };
  }, Ember.Component);
  _exports.default = FeedUserSingle;
});