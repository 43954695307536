define("socialchan/routes/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Page extends Ember.Route {}

  _exports.default = Page;
});