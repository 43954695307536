define("socialchan/templates/group/show/posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uBa4UwqY",
    "block": "{\"symbols\":[\"post\"],\"statements\":[[1,[27,\"component\",[\"post/create\"],[[\"placeholder\"],[[27,\"concat\",[\"Publicar en \",[23,[\"model\",\"group\",\"name\"]],\"...\"],null]]]],false],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"posts\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[\"post/single\"],[[\"post\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/group/show/posts.hbs"
    }
  });

  _exports.default = _default;
});