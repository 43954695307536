define("socialchan/components/element/country-list", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/component", "jquery"], function (_exports, _decorate2, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';
  let ElementCountryList = (0, _decorate2.default)([(0, _component.tagName)('select')], function (_initialize, _EmberComponent) {
    class ElementCountryList extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: ElementCountryList,
      d: [{
        kind: "field",
        key: "selectedCode",

        value() {
          return 'ES';
        }

      }, {
        kind: "method",
        key: "didInsertElement",
        value: function didInsertElement() {
          this._super(...arguments);

          _jquery.default.getJSON('/static/countries.json').done(res => {
            // Ember.run.bind(this, this.set('countries', res));
            this.set('countries', res);
          });
        }
      }]
    };
  }, Ember.Component);
  _exports.default = ElementCountryList;
});