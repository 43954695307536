define("socialchan/models/anime", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-data", "@ember-decorators/data", "socialchan/mixins/model-timestamps"], function (_exports, _decorate2, _emberData, _data, _modelTimestamps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Model = _emberData.default.Model;
  let Anime = (0, _decorate2.default)(null, function (_initialize, _Model$extend) {
    class Anime extends _Model$extend {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: Anime,
      d: [{
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "title",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('boolean')],
        key: "adult",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('boolean')],
        key: "public",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string', {
          defaultValue: function defaultValue() {
            return 'img/cover_default.jpg';
          }
        })],
        key: "image",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "about",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('json')],
        key: "content",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('date')],
        key: "published",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('date')],
        key: "finished",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.belongsTo)('user')],
        key: "author",
        value: void 0
      }]
    };
  }, Model.extend(_modelTimestamps.default));
  _exports.default = Anime;
});