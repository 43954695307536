define("socialchan/templates/components/element/country-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z24AXpS9",
    "block": "{\"symbols\":[\"country\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\"],[4,\"each\",[[23,[\"countries\"]]],null,{\"statements\":[[0,\"  \"],[7,\"option\"],[12,\"value\",[28,[[22,1,[\"code\"]]]]],[12,\"selected\",[27,\"if\",[[27,\"eq\",[[22,1,[\"code\"]],[23,[\"selectedCode\"]]],null],true,false],null]],[9],[0,\"\\n    \"],[1,[22,1,[\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/element/country-list.hbs"
    }
  });

  _exports.default = _default;
});