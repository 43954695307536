define("socialchan/routes/user/friends", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserFriendsRoute extends Ember.Route {
    model() {
      let user = this.modelFor('user');
      return this.get('store').query('user', {
        include: 'profile',
        follows: user.get('id')
      });
    }

  }

  _exports.default = UserFriendsRoute;
});