define("socialchan/adapters/user", ["exports", "socialchan/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserAdapter extends _application.default {
    urlForQueryRecord(query, modelName) {
      if (query.me) {
        const baseUrl = this.buildURL();
        delete query.me;
        return `${baseUrl}/${modelName}/me`;
      }

      return this._super(...arguments);
    }

  }

  _exports.default = UserAdapter;
});