define("socialchan/templates/components/page/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AEqbIhif",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"flex justify-around list-reset py-4 overflow-y-auto scrolling-touch select-none\"],[9],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"mr-6\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"https://dojo.moe\"],[11,\"target\",\"_blank\"],[11,\"class\",\"text-white\"],[9],[0,\"\\n      Dojo\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"mr-6\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"page.subscribe\"],[[\"class\"],[\"text-white\"]],{\"statements\":[[0,\"      Suscripción\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"mr-6\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"https://github.com/Social-chan\"],[11,\"target\",\"_blank\"],[11,\"class\",\"text-white\"],[9],[0,\"\\n      Desarrolladores\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"mr-6\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"page.policies\"],[[\"class\"],[\"text-white\"]],{\"statements\":[[0,\"      Condiciones\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"mr-6\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"page.cookies\"],[[\"class\"],[\"text-white\"]],{\"statements\":[[0,\"      Cookies\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"mr-6\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"page.contact\"],[[\"class\"],[\"text-white\"]],{\"statements\":[[0,\"      Contacto\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"mr-6\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"page.about\"],[[\"class\"],[\"text-white\"]],{\"statements\":[[0,\"      Acerca\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/page/footer.hbs"
    }
  });

  _exports.default = _default;
});