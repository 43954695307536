define("socialchan/routes/application", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-simple-auth/mixins/application-route-mixin", "@ember-decorators/service"], function (_exports, _decorate2, _applicationRouteMixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Application = (0, _decorate2.default)(null, function (_initialize, _EmberRoute$extend) {
    class Application extends _EmberRoute$extend {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: Application,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "moment",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "auth",
        value: void 0
      }, {
        kind: "method",
        key: "beforeModel",
        value: function beforeModel() {
          Ember.get(this, 'moment').setLocale('es');

          this._loadCurrentUser();
        }
      }, {
        kind: "method",
        key: "sessionAuthenticated",
        value: function sessionAuthenticated() {
          this._super(...arguments);

          this._loadCurrentUser();
        }
      }, {
        kind: "method",
        key: "_loadCurrentUser",
        value: function _loadCurrentUser() {
          return Ember.get(this, 'auth').load().catch(() => Ember.get(this, 'session').invalidate());
        }
      }]
    };
  }, Ember.Route.extend(_applicationRouteMixin.default));
  _exports.default = Application;
});