define("socialchan/templates/components/user/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N6AqLAKg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[11,\"class\",\"w-full flex items-center mb-4\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"user\",[23,[\"user\",\"id\"]]],[[\"class\"],[\"flex-no-shrink w-10 mr-4\"]],{\"statements\":[[0,\"    \"],[7,\"img\"],[11,\"class\",\"rounded-full\"],[12,\"src\",[23,[\"user\",\"profile\",\"avatar\"]]],[11,\"alt\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"w-full mr-auto\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"user\",[23,[\"user\",\"id\"]]],null,{\"statements\":[[0,\"      @\"],[1,[23,[\"user\",\"nickname\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"p\"],[11,\"class\",\"text-grey-dark text-sm mt-1\"],[9],[0,\"\\n      \"],[1,[23,[\"user\",\"profile\",\"bio\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"user\",\"isLoggedIn\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"component\",[\"user/follow-button\"],[[\"class\",\"isFollowed\",\"user_id\"],[\"flex-no-shrink\",[23,[\"user\",\"following\"]],[23,[\"user\",\"id\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/user/single.hbs"
    }
  });

  _exports.default = _default;
});