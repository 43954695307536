define("socialchan/templates/components/user/follow-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LcKUVnYz",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isFollowed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[28,[\"bg-transparent hover:bg-red border border-red text-red hover:text-white text-\",[21,\"size\"],\" font-semibold rounded px-2 py-1 \",[21,\"addClass\"]]]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fas fa-user-times\"],[9],[10],[0,\"\\n    \"],[1,[27,\"if\",[[27,\"eq\",[[23,[\"size\"]],\"xs\"],null],\"\",\"No seguir\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"toggleFollow\",[23,[\"user_id\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[28,[\"bg-transparent hover:bg-blue border border-blue text-blue hover:text-white text-\",[21,\"size\"],\" font-semibold rounded px-2 py-1 \",[21,\"addClass\"]]]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fas fa-user-plus\"],[9],[10],[0,\"\\n    \"],[1,[27,\"if\",[[27,\"eq\",[[23,[\"size\"]],\"xs\"],null],\"\",\"Seguir\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"toggleFollow\",[23,[\"user_id\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "socialchan/templates/components/user/follow-button.hbs"
    }
  });

  _exports.default = _default;
});