define("socialchan/routes/user/posts", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-simple-auth/mixins/authenticated-route-mixin", "@ember-decorators/service"], function (_exports, _decorate2, _authenticatedRouteMixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let UserPosts = (0, _decorate2.default)(null, function (_initialize, _EmberRoute$extend) {
    class UserPosts extends _EmberRoute$extend {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: UserPosts,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "store",
        value: void 0
      }, {
        kind: "method",
        key: "model",
        value: function model() {
          let user = this.modelFor('user');
          return Ember.RSVP.hash({
            user: user,
            posts: Ember.get(this, 'store').query('post', {
              include: 'author, author.profile',
              filter: {
                user_id: Ember.get(this, 'user.id')
              }
            })
          });
        }
      }]
    };
  }, Ember.Route.extend(_authenticatedRouteMixin.default));
  _exports.default = UserPosts;
});