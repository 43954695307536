define("socialchan/components/layout/sidebar-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LayoutSidebarSearch extends Ember.Component {}

  _exports.default = LayoutSidebarSearch;
});