define("socialchan/components/group/single", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/component"], function (_exports, _decorate2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let GroupSingle = (0, _decorate2.default)([(0, _component.classNames)('bg-grey-lightest flex items-center rounded mb-3 p-3')], function (_initialize, _EmberComponent) {
    class GroupSingle extends _EmberComponent {
      constructor() {
        super(...arguments);

        _initialize(this);
      }

    }

    return {
      F: GroupSingle,
      d: []
    };
  }, Ember.Component);
  _exports.default = GroupSingle;
});